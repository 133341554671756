@font-face {
	font-family: 'VCR OSD Mono';
	src: url('../fonts/VCROSDMono.woff2') format('woff2'),
			url('../fonts/VCROSDMono.woff') format('woff'),
			url('../fonts/VCROSDMono.svg#VCROSDMono') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

*,
*::after,
*::before {
	box-sizing: border-box;
}

:root {
	font-size: 18px;
}

body {
	margin: 0;
	--color-text: #111;
	--color-bg: #F9401C;
	--color-link: #111;
	--color-link-hover: #fff;
	--color-bg-alt: #ffffff;
	--color-text-alt: #111;

	color: var(--color-text);
	background-color: var(--color-bg);
	font-family: 'VCR OSD Mono', sans-serif;
	text-transform: uppercase;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow-x: hidden;
}

/* Page Loader */
.js .loading::before,
.js .loading::after {
	content: '';
	position: fixed;
	z-index: 1000;
}

.js .loading::before {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: var(--color-bg);
}

.js .loading::after {
	top: 50%;
	left: 50%;
	width: 60px;
	height: 60px;
	margin: -30px 0 0 -30px;
	border-radius: 50%;
	opacity: 0.4;
	background: var(--color-link);
	animation: loaderAnim 0.7s linear infinite alternate forwards;

}

@keyframes loaderAnim {
	to {
		opacity: 1;
		transform: scale3d(0.5,0.5,1);
	}
}

a {
	text-decoration: none;
	color: var(--color-link);
	outline: none;
}

a:hover {
	color: var(--color-link-hover);
	outline: none;
}

/* Better focus styles from https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible */
a:focus {
	/* Provide a fallback style for browsers
	 that don't support :focus-visible */
	outline: none;
	background: lightgrey;
}

a:focus:not(:focus-visible) {
	/* Remove the focus indicator on mouse-focus for browsers
	 that do support :focus-visible */
	background: transparent;
}

a:focus-visible {
	/* Draw a very noticeable focus style for
	 keyboard-focus on browsers that do support
	 :focus-visible */
	outline: 2px solid red;
	background: transparent;
}

.unbutton {
	background: none;
	border: 0;
	padding: 0;
	margin: 0;
	font: inherit;
}

.unbutton:focus {
	outline: none;
}

.frame {
	position: fixed;
	z-index: 1000;
	top: 0;
	left: 0;
	display: grid;
	align-content: space-between;
	width: 100%;
	max-width: none;
	padding: 1rem;
	pointer-events: none;
	grid-template-columns: 1fr;
	grid-template-rows: repeat(4,auto);
	grid-template-areas: 'title' 'prev' 'sponsor' 'demos' ;
}

.frame a,
.frame button {
	pointer-events: auto;
}

.frame__title {
	grid-area: title;
	justify-content: flex-start;
	align-items: center;
	display: flex;
	margin-bottom: 0.5rem;
}

.frame__title-main {
	font-size: 1rem;
	margin: 0;
	font-weight: 400;
}

.frame__title-back {
	position: relative;
	display: flex;
	align-items: flex-end;
}

.frame__title-back span {
	display: none;
}

.frame__title-back svg {
	fill: var(--color-link);
}

.frame__title-back:hover svg,
.frame__title-back:focus svg {
	fill: var(--color-link-hover);
}

.frame__demos {
	grid-area: demos;
}

.frame__demos a {
	margin-left: 2rem;
	position: relative;
}

.frame__demo--current,
.frame__demo--current:hover {
	color: var(--color-text);
}

.frame__prev {
	grid-area: prev;
	justify-self: start;
}

.frame__demos-title {
	display: block;
}

.grid {
	position: relative;
	width: 100%;
	display: grid;
	grid-template-columns: repeat(8,1fr);
}

.grid__item {
	position: relative;
	will-change: transform;
	grid-column: var(--c);
	grid-row: var(--r);
}

.grid__item-img {
	position: relative;
	width: 100%;
	height: auto;
	aspect-ratio: 1;
	background-size: cover;
	background-position: 50% 50%;
	will-change: transform, opacity;
}


.cover {
	position: fixed;
	width: 100%;
	height: 100vh;
	top: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	pointer-events: none;
}

.cover img {
	width: 50vw;
}

.links {
	position: fixed;
	width: 100%;
	height: 100vh;
	top: 0;
	left: 0;

	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: repeat(2, 1fr);
	grid-template-areas:
		'top-left top-right'
		'bottom-left bottom-right';
	
	padding: 2vh 7.2vw 4vh;
	font-size: 1.4vw;
}

.links__ado{
	grid-area: top-left;
	justify-self: start;
	align-self: start;
}

.links__date{
	grid-area: top-right;
	justify-self: end;
}

.links__name {
	grid-area: bottom-left;
	justify-self: start;
	align-self: end;
}

/* .links__drive:after {
	content: '';
	width: 100%;
	height: 3px;
	display: block;
	background-color: var(--color-link);
} */


.links__drive {
	grid-area: bottom-right;
	align-self: end;
	justify-self: end;
}

@media (max-width: 767px) {
	.grid {
		grid-template-columns: repeat(4, 1fr);
	}

	.grid__item {
		grid-column: var(--c-mob);
	}

	.links {
		padding: 2vh 2vw 8vh;
		font-size: 3.1vw;
	}

	/* .links__drive:after {
		content: '';
		width: 100%;
		height: 2px;
		display: block;
		background-color: var(--color-link);
	} */

	.cover img {
		width: 70vw;
	}
}
